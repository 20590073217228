import React, { useState } from 'react'
import Slider from '@/components/slider'
import Icon from '@/components/icon'
import LazyImage, { Type } from '@/components/lazy-image'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { t } from '@lingui/macro'
import { Carousel } from '@nbit/arco'
import classNames from 'classnames'
import { useCommonStore } from '@/store/common'
import { ROUTE_PATH } from '@/constants/route'
import Link from '@/components/link'
import styles from './index.module.css'

export enum ServiceListID {
  social = 'social',
  vpn = 'vpn',
  proxy = 'proxy',
  dev = 'dev',
}

export const getServiceList = () => [
  {
    id: ServiceListID.social,
    title: t`features_layout_header_menus_url_sctzkci_5x`,
    desc: [
      t`features_home_services_m5vjgkrizs`,
      t`features_home_services_pmt4zt9nqk`,
      t`features_home_services_ks9dhxw9ka`,
    ],
    learnMore: 'https://talkblink.com',
  },
  {
    id: ServiceListID.vpn,
    title: t`features_home_services_3cvnrndhjj`,
    desc: [
      t`features_home_services_ndpimifns4`,
      t`features_home_services_kpvmnbdmpk`,
      t`features_home_services_udeazbstgp`,
    ],
    learnMore: ROUTE_PATH.CONTACT_US,
  },
  {
    id: ServiceListID.proxy,
    title: t`features_layout_header_menus_url_gjj2xjiojx`,
    desc: [
      t`features_home_services_u3zj7eixlm`,
      t`features_home_services_arykvllunw`,
      t`features_home_services_mv68bqec2x`,
    ],
    learnMore: ROUTE_PATH.CONTACT_US,
  },
  {
    id: ServiceListID.dev,
    title: t`features_home_services_7oltmd3oq2`,
    desc: [
      t`features_home_services_l4kz8t5nzx`,
      t`features_home_services_8wzpskni1j`,
      t`features_home_services_hkbenb1uud`,
    ],
    learnMore: ROUTE_PATH.CONTACT_US,
  },
]
function Services() {
  const { locale } = useCommonStore()
  // H5 端
  const [activeIndex, setActiveIndex] = useState(0)
  // web 端
  const [currentIndex, setCurrentIndex] = useState(1)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1.15,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 1600,
    // 由于 slidesToShow 不是整数，所以 newIndex 也不一定是整数，这里向上取整
    afterChange: newIndex => setActiveIndex(Math.ceil(newIndex)),
  }
  return (
    <div className={styles.services}>
      <div className="title">{t`features_layout_header_menus_url__45xlkluau`}</div>
      <div className="slick-wrapper">
        <Slider {...settings}>
          {getServiceList().map((item, index) => {
            const isActive = index === activeIndex
            return (
              <div className="item-wrapper" key={item.id}>
                <Link href={item.learnMore} target={item.id === ServiceListID.social}>
                  <div
                    className="item"
                    style={{
                      background: `center/cover no-repeat url(${oss_svg_image_domain_address}${
                        isActive ? 'h5_home_services_image_pre' : 'home_services_image_nor'
                      }.png)`,
                    }}
                  >
                    <div className="content-text">
                      <div className="services-list-title">{item.title}</div>
                      <ul className="services-list">
                        {item.desc.map(i => (
                          <li className="services-list-item" key={i}>
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {locale === 'en-US' ? null : (
                      <LazyImage
                        className="img"
                        width={500}
                        imageType={Type.png}
                        src={`${oss_svg_image_domain_address}home_services_${item.id}_pre`}
                      />
                    )}
                  </div>
                </Link>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="hidden lg:block relative">
        <Carousel
          autoPlay
          animation="card"
          showArrow="always"
          currentIndex={currentIndex}
          onChange={setCurrentIndex}
          indicatorType="never"
          icons={{
            prev: <Icon name="icon_arrow_previous_nor" hoverName="icon_arrow_previous_pre" />,
            next: <Icon name="icon_arrow_next_nor" hoverName="icon_arrow_next_pre" />,
          }}
        >
          {getServiceList().map((item, index) => (
            <div key={index} className="services-item-box">
              <Link href={item.learnMore} target={item.id === ServiceListID.social}>
                <div className={classNames('services-item', { active: currentIndex === index })}>
                  <LazyImage
                    className={`img img-${item.id}`}
                    imageType={Type.png}
                    src={`${oss_svg_image_domain_address}home_services_${item.id}_pre`}
                  />
                  <div className={classNames('content-text', { en: locale === 'en-US' })}>
                    <div className="services-list-title">{item.title}</div>
                    <ul className="services-list">
                      {item.desc.map(i => (
                        <li className="services-list-item" key={i}>
                          {i}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Services
